import React from 'react'
import "../Nashikprices/Nashikprice.css"; 
import { Pricecard } from '../Pricecard/Pricecard.jsx';

export default function Nashikprice() {
  return (
    <>
        <div className="Nashikprice">
          <div className="container-fluid">
            <div className="container">
                <h1 className='text-center Nashikprice_title'>PRICING <span className="green">NASHIK</span></h1>
                <div className="mx-auto border-3 title-border-bottom w-50"></div>
              <div className="row Nashikprice_Outer pt-4">
                <div className="col-12 col-md-6 Nashikprice_card g-5">
                    <Pricecard
                        packagesName="Single Session"
                        packagePrice="949/-"
                        pkalistpoint1="Daily Online Payment"
                        pkalistpoint2="Session: Upto 45 Minutes"
                        pkalistpoint3="For Single Session"
                        pkalistpoint4="Alternate Session"
                        packagesLink="/about"
                        pkabuttonText="BOOK NOW"
                        
                    />
                </div>
                <div className="col-12 col-md-6 Nashikprice_card g-5">
                    <Pricecard
                        packagesName="7 Days Package"
                        packagePrice="5950/-"
                        pkalistpoint1="Advance Online Payment"
                        pkalistpoint2="Session: Upto 45 Minutes"
                        pkalistpoint3="For 7 Session"
                        pkalistpoint4="No Alternate Session"
                        packagesLink="/about"
                        pkabuttonText="BOOK NOW"
                        
                    />
                </div>
                <div className="col-12 col-md-6 Nashikprice_card g-5">
                    <Pricecard
                        packagesName="15 Days Package"
                        packagePrice="12000/-"
                        pkalistpoint1="Advance Online Payment"
                        pkalistpoint2="Session: Upto 45 Minutes"
                        pkalistpoint3="For 15 Session"
                        pkalistpoint4="No Alternate Session"
                        packagesLink="/about"
                        pkabuttonText="BOOK NOW"
                        
                    />
                </div>
                <div className="col-12 col-md-6 Nashikprice_card g-5">
                    <Pricecard
                        packagesName="30 Days Package"
                        packagePrice="21000/-"
                        pkalistpoint1="Advance Online Payment"
                        pkalistpoint2="Session: Upto 45 Minutes"
                        pkalistpoint3="For 30 Session"
                        pkalistpoint4="No Alternate Session"
                        packagesLink="/about"
                        pkabuttonText="BOOK NOW"
                        
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

import React from 'react'
import "../Careercard/Careercard.css";
import { Link } from "react-router-dom";

export const Careercard =({
    CareersName,
    Careerslistheading,
    Careerslist1,
    Careerslist1link,
    Careerslist2,
    Careerslist2link,
    Careerslist3,
    Careerslist3link
})=>{
    return(
        <>
        <div className="Careercard_Outer">
            <div className="Careercard_container">
                 <div className="Careercard_inner__Headings">
                     <h4 className="Careercard_title text-center p-0">{CareersName}</h4>
                </div>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-12 pt-5">
                            <div className="Careercard_content">
                                <ul className='Careercard_content_list'>
                                    <p>{Careerslistheading}</p>
                                    <li><Link to={Careerslist1link}>{Careerslist1}</Link></li>
                                    <li><Link to={Careerslist2link}>{Careerslist2}</Link></li>
                                    <li><Link to={Careerslist3link}>{Careerslist3}</Link></li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

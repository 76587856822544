import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Homepage from './components/Homepage/Homepage';
import Footer from './components/Footer/Footer';
import Nandedprice from './components/Nandedprices/Nandedprice';
import Mumbaiprice from './components/Mumbaiprices/Mumbaiprice';
import PimpriChinchwadprice from './components/PimpriChinchwadprices/PimpriChinchwadprice';
import Puneprice from './components/Puneprices/Puneprice';
import Nagpurprice from './components/Nagpurprices/Nagpurprice';
import Bangaloreprice from './components/Bangaloreprices/Bangaloreprice';
import Neurological from './components/Bestservices/Neurological';
import Physiotherapy from './components/Bestservices/Physiotherapy';
import CardiacRehab from './components/Bestservices/Cardiac_Rehab';
import Geriatric from './components/Bestservices/Geriatric';
import Gynecological from './components/Bestservices/Gynecological';
import Orthopedic from './components/Bestservices/Orthopedic';
import Pediatric from './components/Bestservices/Pediatric';
import PostFracture from './components/Bestservices/Post_Fracture';
import PostSurgery from './components/Bestservices/Post_Surgery';
import RespiratoryPhysiotherapy from './components/Bestservices/Respiratory_Physiotherapy';
import SportInjury from './components/Bestservices/Sport_Injury';
import MassageTherapy from './components/Bestservices/Massage_Therapy';
import BloodTest from './components/Bestservices/Blood_Test';
import CareTaker from './components/Bestservices/Care_Taker';
import Career from './components/Career/Career';
import Popup from './components/Popup/Popup';
import LoginModule from './components/Clientlogin/LoginModule';
import LoanAudits from './components/Clientlogin/Audit';
import About from './components/About/About';
import Careercaretaker from './components/Careerpages/Careercaretaker';
import Careermassager from './components/Careerpages/Careermassager';
import Careerbloodtester from './components/Careerpages/Careerbloodtester';
import Careerhradmin from './components/Careerpages/Careerhradmin';
import Careeraccountexecutive from './components/Careerpages/Careeraccountexecutive';
import Careercustomersupport from './components/Careerpages/Careercustomersupport';
import Services from './components/Services/Services';
import Ahmedabadprice from './components/Ahmedabadprices/Ahmedabadprice';
import Nashikprice from './components/Nashikprices/Nashikprice';

function App() {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
  
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
  return (
    <div className="container-fluid p-0">
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Nanded" element={<Nandedprice />} />
        <Route path="/Mumbai" element={<Mumbaiprice />} />
        <Route path="/Pimpri_Chinchwad" element={<PimpriChinchwadprice />} />
        <Route path="/Pune" element={<Puneprice />} />
        <Route path="/Nagpur" element={<Nagpurprice />} />
        <Route path="/Bangalore" element={<Bangaloreprice />} />
        <Route path="/Ahmedabad" element={<Ahmedabadprice />} />
        <Route path="/Nashik" element={<Nashikprice />} />
        <Route path="/Neurological" element={<Neurological />} />
        <Route path="/Physiotherapy" element={<Physiotherapy />} />
        <Route path="/Cardiac_Rehab" element={<CardiacRehab />} />
        <Route path="/Geriatric" element={<Geriatric />} />
        <Route path="/Gynecological" element={<Gynecological />} />
        <Route path="/Orthopedic" element={<Orthopedic />} />
        <Route path="/Pediatric" element={<Pediatric />} />
        <Route path="/Post_Fracture" element={<PostFracture />} />
        <Route path="/Post_Surgery" element={<PostSurgery />} />
        <Route path="/Respiratory_Physiotherapy" element={<RespiratoryPhysiotherapy />} />
        <Route path="/Sport_Injury" element={<SportInjury />} />
        <Route path="/Massage_Therapy" element={<MassageTherapy />} />
        <Route path="/Blood_Test" element={<BloodTest />} />
        <Route path="/Care_Taker" element={<CareTaker />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/OpsUser" element={<LoginModule />} />
        <Route path="/sessions-audit" element={<LoanAudits />} />
        <Route path="/about" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Careercaretaker" element={<Careercaretaker />} />
        <Route path="/Careermassager" element={<Careermassager />} />
        <Route path="/Careerbloodtester" element={<Careerbloodtester />} />
        <Route path="/Careerhradmin" element={<Careerhradmin />} />
        <Route path="/Careeraccountexecutive" element={<Careeraccountexecutive />} />
        <Route path="/Careercustomersupport" element={<Careercustomersupport />} />
      </Routes>
      <Footer />
      <Popup />
    </div>
  );
}

export default App;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars,faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import "../Navbar/Navbar.css";
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <>
    <div className="Navbar-top">
        <div className="container-fuild">
            <div className="container">
                <div className="Nav_Fixed">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid Navbar-outer">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="Navbar-img">
                        <img src="assets/Physio_Relief_Logo.png" width="150px" height="110px" alt="Img" />
                        </div>
                        <Link to="/" className="navbar-brand fs-2 text-white text-decoration-none" >Physio<span className='logo-text'>Relief</span></Link>
                </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span><FontAwesomeIcon icon={faBars} /></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                    <Link to="/" className="nav-link active">Home</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link to="/Services" className="dropdown-item">Physiotherapy</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Care_Taker" className="dropdown-item">Care Taker</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Blood_Test" className="dropdown-item">Blood Test</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Massage_Therapy" className="dropdown-item">Massage Therapy</Link></li>
                                    </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="\" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Pricing
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        
                                        <li><Link to="/Pune" className="dropdown-item">Pune</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Pimpri_Chinchwad" className="dropdown-item">Pimpri - Chinchwad</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Mumbai" className="dropdown-item">Mumbai</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Bangalore" className="dropdown-item">Bangalore</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Nagpur" className="dropdown-item">Nagpur</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Nanded" className="dropdown-item">Nanded</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Nashik" className="dropdown-item">Nashik</Link></li>
                                        <li><hr className="dropdown-divider m-0"/></li>
                                        <li><Link to="/Ahmedabad" className="dropdown-item">Ahmedabad</Link></li>
                                    </ul>
                                    </li>
                                    <li className="nav-item">
                                    <Link to="/Career" className="nav-link">Career</Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link className="nav-link active" aria-current="page" to="tel:+919022972131"><FontAwesomeIcon icon={faPhoneVolume} /> +919022972131</Link>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

import React from 'react'
import "../Bestservices/Bestservices.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons' 
export default function Orthopedic() {
  return (
    <>
        <div className="container-fluid Bestservices_outer">
            <div className="container Bestservices_inner">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Bestservices_heading'>Orthopedic</h1>
                        <div className="mx-auto border-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Services"><div className='services-back-button'><FontAwesomeIcon icon={faArrowLeft} className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 bestservicesIMG g-5">
                        <div className="best_services_outer">
                            <div className="best_services_inner">
                                <div className="best_services_img">
                                <img src="assets\Orthopedic.jpg" alt="Img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 bestservicesDATA g-5">
                        <div className="best_services_outer_data">
                            <div className="best_services_inner_data">
                                <div className="best_services_data">
                                <p className="lead">Orthopedic physiotherapy, also known as orthopedic physical therapy, is a specialized branch of physical therapy that focuses on the assessment, treatment, and rehabilitation of musculoskeletal injuries and conditions. This field of physiotherapy deals primarily with problems related to the musculoskeletal system, which includes bones, joints, muscles, ligaments, tendons, and nerves.</p>
                                <p className="lead">The goals of orthopedic physiotherapy are to reduce pain, improve function, restore mobility, and enhance overall quality of life for individuals with orthopedic conditions. This can involve a variety of techniques and interventions tailored to the specific needs of each patient.</p><p className="lead"><ol><li>Fractures</li><li>Sprains and strains</li><li>Joint dislocations</li><li>Tendonitis</li><li>Arthritis</li><li>Post-operative rehabilitation (e.g., joint replacements, ligament repairs)</li><li>Back and neck pain</li><li>Scoliosis</li><li>Osteoporosis</li><li>Sports injuries</li></ol></p><p className="lead"><ol><h3>Orthopedic physiotherapy treatments may include:</h3><li>Manual therapy techniques such as joint mobilization and manipulation to improve joint mobility and reduce stiffness.</li><li>Therapeutic exercises to strengthen muscles, improve flexibility, and restore functional movement patterns.</li><li>Modalities such as heat, cold, ultrasound, electrical stimulation, and laser therapy to manage pain and promote tissue healing.</li><li>Functional training to help patients regain the ability to perform daily activities and return to work or sports.</li><li>Education and advice on posture, ergonomics, and lifestyle modifications to prevent future injuries.</li></ol></p><p className="lead">Orthopedic physiotherapists work closely with patients to develop personalized treatment plans based on their specific needs and goals. Treatment may progress gradually as the patient's condition improves, with regular reassessment to monitor progress and adjust interventions as needed. The ultimate aim is to help patients optimize their physical function and lead active, pain-free lives.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React from 'react'
import "../Popup/Popup.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

export default function Popup() {
  return (
    <>
        <div className="container-fuild">
            <div className="container">
                <div className="Popup_outer">
                    <div className="Popup_inner w-50 h-50">
                        <Link className="nav-link" aria-current="page" to="tel:+919022972131"><FontAwesomeIcon icon={faPhoneVolume} /></Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React from 'react'
import '../Card1/Card.css';  
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar,faQuoteLeft,faQuoteRight } from '@fortawesome/free-solid-svg-icons'

export const Card =({
    cardName,
    cardSubname,
    cardCity,
    ratings,
    description,
    knowMore,
    knowMoretext,
    buttonText,
    cardLink
})=>{
    return(
        <>
        <div className="card__outer__body">
            <div className="card__inner__body">
                <div className="qouteLeft">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <div className="card__container">
                    <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="card__headings">
                            <h2 className="card__title"><span className="green">{cardName}</span></h2>
                            <h6 className='card_sub_title'>{cardSubname}</h6>
                            <p className='review_card_city'>({cardCity})</p>
                        </div>
                        <div className= "card__ratings">{ratings}<FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-12">
                        <div className="card__texts">
                                <p className="lead">{description}</p>
                                <Link to={knowMore} className='card__knowMore'>{knowMoretext}</Link>
                                <Link to={cardLink} className='card__btn'>{buttonText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="qouteRight">
                    <FontAwesomeIcon icon={faQuoteRight} />
                </div>
            </div>
        </div>
        </>
        
    );
};

import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";


const LoanAudits = () => {
  

  const loadAuditAPI = async () => {
    try {
      const response = await fetch('http://localhost:1421/physio-relief/fetch-session-audit/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify()
      });

      if (!response.ok) {
        throw new Error('Failed to login');
      }

      const data = await response.json();

      // Customizing the alert popup with CSS
      const alertPopup = document.createElement('div');
      alertPopup.textContent = data.message;
      alertPopup.classList.add('custom-alert'); // Add your CSS class here

      document.body.appendChild(alertPopup);

      // Automatically remove the popup after 5 seconds
      setTimeout(() => {
        alertPopup.remove();
      }, 5000);

      return data;
    } catch (error) {
      console.error('Error:', error);

      // Customizing the error alert popup with CSS
      const alertPopup = document.createElement('div');
      alertPopup.textContent = error.message;
      alertPopup.classList.add('custom-alert-error'); // Add your CSS class here

      document.body.appendChild(alertPopup);

      // Automatically remove the popup after 5 seconds
      setTimeout(() => {
        alertPopup.remove();
      }, 5000);
    }
  };

  useEffect(() => {
    loadAuditAPI()
    .then((response) => {
      // Handle API response
      setUnAssignedCasesRes(response);
    })
    .catch((error) => {
      console.error("Failed to load audit data:", error);
    });
  }, []); 

  const [unAssignedCasesRes, setUnAssignedCasesRes] = useState();
  const [pageIndex2, setPageIndex2] = useState(0);
  
  const data = React.useMemo(() => {
    if (!unAssignedCasesRes || !unAssignedCasesRes.sessionAuditList) return [];
  
    return unAssignedCasesRes.sessionAuditList.map((audit) => ({
      auditId:audit.session_audit_id,
      loanApplicationId: audit.user_name,
      creationDateTime: audit.user_email,
      stage: audit.service,
      description: audit.city,
    }));
  }, [unAssignedCasesRes]);
  
  const columns = React.useMemo(
    () => [
      {Header: "Audit ID", accessor:"auditId"},
      { Header: "User Name", accessor: "loanApplicationId" },
      { Header: "User Email", accessor: "creationDateTime" },
      { Header: "Service", accessor: "stage" },
      { Header: "City", accessor: "description" },
    ],
    []
  );

  // Table 2
  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    rows: rows2,
    prepareRow: prepareRow2,
    page: page2,
    canPreviousPage: canPreviousPage2,
    canNextPage: canNextPage2,
    nextPage: nextPage2,
    previousPage: previousPage2,
    gotoPage: gotoPage2,
    pageCount: pageCount2,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex2: pageIndex2, pageSize: 20 }, // Set initial page index and page size
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="row " style={{paddingTop:'140PX',paddingRight:'20PX',paddingLeft:'20px'}}>
      <div className="col-12">
        <div className="row">
          <div className="col-12 mb-">
            <span style={{ fontWeight: "bold" }}>Sessions Audit</span>
          </div>
          <div className="col-4">
            
          </div>
        </div>
      </div>

      <div >
        <div className="col-12 text-center mt-2">
          {
            <div style={{ height: 400, width: "100%" }}>
              <table
                {...getTableProps2()}
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  {headerGroups2.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            padding: "8px",
                            background: "#EEEEEE",
                            borderBottom: "1px solid #ddd",
                            textAlign: "left",
                            fontSize: "13px",
                          }}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps2()}>
                  {rows2.slice(0, 20).map((row, index) => {
                    prepareRow2(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{
                          background: index % 2 === 0 ? "#ffffff" : "#F1F6FF",
                        }}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: "8px",
                              fontSize: "13px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                              color:
                                cellIndex === 0 ||
                                cellIndex === 2 ||
                                cellIndex === 4
                                  ? "gray"
                                  : "black",
                            }}
                          >
                            {
                              <span style={{ color: "black" }}>
                                {cell.render("Cell")}
                              </span>
                            }
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default LoanAudits;

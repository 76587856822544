import React, { useState } from 'react';
import "../Form/Form.css";

export default function Form() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    service: '',
    city: '',
    email_id: ''
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop the form submission if there are errors
    }

    try {
      await fetch('https://script.google.com/macros/s/AKfycbzNG7UgHIf1g5YoCEFIdSsmJx8CBWi76X5AA0R_BV2DmWzEjKttPeTcR5BWFD7ZFDIrMQ/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      showAlert('Thank you! We have received your details.', 'success');

      // Reset form data
      setFormData({
        name: '',
        mobile: '',
        service: '',
        city: '',
        email_id: ''
      });

      setErrors({}); // Reset errors after submission
    } catch (error) {
      console.error('Error:', error);
      showAlert('Failed to book session. Please try again.', 'error');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showAlert = (message, type) => {
    const alertPopup = document.createElement('div');
    alertPopup.textContent = message;
    alertPopup.classList.add(type === 'success' ? 'custom-alert' : 'custom-alert-error');
    document.body.appendChild(alertPopup);
    setTimeout(() => alertPopup.remove(), 1000);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Name
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    // Validate Email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email_id || !emailPattern.test(formData.email_id)) {
      newErrors.email_id = 'Valid email is required';
    }

    // Validate Mobile Number (must be exactly 10 digits)
    if (!formData.mobile || formData.mobile.length !== 10) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    // Validate City
    if (!formData.city) {
      newErrors.city = 'Please select a city';
    }

    // Validate Service
    if (!formData.service) {
      newErrors.service = 'Please select a service';
    }

    return newErrors;
  };

  return (
    <div className="container-fulid">
      <div className="container-form">
        <div className="row">
          <div className="col-12 bookAppoinment">
            <div className="text-white">
              <h3 className='bookAppoinment_heading'>Book Your Session</h3>
              <form onSubmit={handleSubmit} className="row g-3 text-center">
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleChange}
                    required
                  />
                  {errors.email_id && <span className="error">{errors.email_id}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="tel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Mobile Number"
                    maxLength="10"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                  {errors.mobile && <span className="error">{errors.mobile}</span>}
                </div>
                <div className="col-12">
                  <select
                    id="inputState"
                    className="form-select dropdown_option_text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>Select Your City</option>
                    <option className='text-dark' value="Pune">Pune</option>
                    <option className='text-dark' value="Mumbai">Mumbai</option>
                    <option className='text-dark' value="Banglore">Bangalore</option>
                    <option className='text-dark' value="Nanded">Nanded</option>
                    <option className='text-dark' value="Nagpur">Nagpur</option>
                    <option className='text-dark' value="Pimpri_Chinchwad">Pimpri-Chinchwad</option>
                  </select>
                  {errors.city && <span className="error">{errors.city}</span>}
                </div>
                <div className="col-12">
                  <select
                    id="inputState"
                    className="form-select dropdown_option_text"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>Select Your Service</option>
                    <option className='text-dark' value="Physiotherapy">Physiotherapy</option>
                    <option className='text-dark' value="Massage Therapy">Massage Therapy</option>
                    <option className='text-dark' value="Care Taker">Care Taker</option>
                    <option className='text-dark' value="Blood Test">Blood Test</option>
                  </select>
                  {errors.service && <span className="error">{errors.service}</span>}
                </div>
                <div className="col-12">
                  <button type="submit" className="btn-physio">
                    <h4>Book Now</h4>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import "../Careerpages/Careerpages.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default function Careercustomersupport() {
  return (
    <>
        <div className="container-fluid Career_pages_outer">
            <div className="container Career_pages_inner">
            <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Career_pages_heading'>Sales & Customer Support Executive</h1>
                        <div className="mx-auto border-3 mb-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Career"><div className='career-back-button'><FontAwesomeIcon icon={faArrowLeft} 
                         className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 Career_pagesDATA g-5">
                        <div className="Career-pages_outer_data">
                            <div className="Career-pages_inner_data">
                                <div className="Career-pages_data">
                                   <p className="lead"><b>Roles and Responsibilities</b><br/><br/><ul><li>You will be required to convert leads.</li><li>Manage inbound calls/emails/chats in a timely manner.</li><li>Follow communication scripts/templates when handling different topics.</li><li>You will be required to create & Manage documentation of clients.</li><li>Identify customers needs, clarify information, research every issue and provide solutions and/or alternatives.</li><li>Sells services by establishing contact and developing relationships with prospects; recommending solutions.</li><li>Meet personal/team qualitative and quantitative targets.</li><li>Must have good command over English writing and speaking.</li><li>Builds business by identifying and selling prospects; maintaining relationships with clients.</li><li>Take timely feedback from the clients.</li><li>Training will be provided by the Company.</li><li>Other Sales & Back office related function.</li></ul><b>Candidate Profile</b><ul><li>BBA, BCA, BSc, B.Com, BBM, M.Com, MBA or PGDBM</li><li>0 to 3 Years Relevant Experience</li><li>Freshers can Apply</li><li>Verbal & Written Communication Skills</li><li>Hardworking & Presentable</li><li>Candidates who can manage Hectic Schedule</li></ul><b>Job Location & Job Type</b><br/><ul><li>Location : Pune</li><li>Full-time Job, Regular / Permanent Job</li><li>Work from Office, No WFH</li></ul><br/><b>Benefits</b><br/><ul><li>Fixed Salary</li><li>Weekly Off, Festival Leaves, Paid Leaves</li><li>Leave encashment</li><li>Health & PA Insurance Benefits</li></ul><br/><b>How to Apply?</b><br/><ul><li>Mobile Number :- <Link to="tel:+919421619929"  target="blank"><span>+91 9421619929</span></Link></li><li>Gmail :- <Link to="mailto:hrphysiorelief@gmail.com"  target="blank"><span>hrphysiorelief@gmail.com</span></Link></li><li>Or, Apply through our Portal.</li></ul></p>
                                </div>
                                <div className="row pt-3">
                                <div className="col-12">
                                    <div className="Careercard__texts">
                                            <div className='Careercard__btn__outer'>
                                                <div className="Careercard__btn__inner">
                                                    <Link to="mailto:hrphysiorelief@gmail.com"  target="blank" className='Careercard__btn__links'><span className='Careercard__btn__text'>Apply Now </span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

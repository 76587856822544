import React, { useState, useEffect } from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Owlcarousel/Owl.css';
import { Card } from '../Card1/Card.jsx';
// import CardTest from '../components/CardTest.js';

const Owldemo1 = () => {
    const [itemsToShow, setItemsToShow] = useState(2); // Default to 2 items
  
    useEffect(() => {
        const handleResize = () => {
          // Adjust the breakpoint as needed
          if (window.innerWidth <= 500) {
            setItemsToShow(1);
          } else if (window.innerWidth <= 950) {
            setItemsToShow(2);
          } else {
            // Handle other cases, maybe set a default number of items to show
            setItemsToShow(3); // Or whatever default number you want
          }
        };
        // Initial setup
        handleResize();
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, 
    []);
    
  
    return (
      <div>
        <div>
          {/* Your title or other components can go here */}
        </div>
        <div className='row'>
          <OwlCarousel items={itemsToShow} margin={8} autoplay={true} nav loop>
              <div className='Owl__Card'>
                <Card
                      cardName="Vijay Waidande"
                      cardSubname="Incharge Blood Bank Symboisys Medical College"
                      cardCity="Pune"
                      description="Outstanding experience with PhysioRelief! The convenience of receiving expert care at home was a amazing. The physiotherapist demonstrated a high level of skill, adapting exercises to my space. The personalized approach, combined with punctuality and professionalism, made each session effective and enjoyable. Grateful for the exceptional care received – a fantastic option for anyone seeking physiotherapy at home!"
                      
                  />
              </div>
              <div className='Owl__Card'>
                <Card
                      cardName="Pramod Pardeshi"
                      cardSubname="Forest Officer"
                      cardCity="Nashik"
                      description="Incredible neurotherapy physiotherapy service brought to my home by PhysioRelief. The specialized care I received was transformative. The therapist's expertise in neurorehabilitation was evident, and the personalized home sessions made a significant impact on my well-being. Punctual, professional, and deeply knowledgeable, this service is a true asset for those seeking top-tier neurotherapy in the comfort of their homes. Highly recommend!"
                  />
              </div>
              <div className='Owl__Card'>
                <Card
                      cardName="Babita Thorat"
                      cardSubname="Social Worker"
                      cardCity="Nanded"
                      description="Exceptional orthopaedic physiotherapy brought to my doorstep by PhysioRelief. The convenience of tailored sessions at home significantly aided my recovery. The knowledgeable therapist demonstrated expertise in addressing orthopaedic concerns, offering personalized exercises that contributed to noticeable improvement. Professional, punctual, and effective – a highly recommended service for anyone seeking orthopaedic physiotherapy in the comfort of their home."
                  />
              </div>
              <div className='Owl__Card'>
                <Card
                      cardName="Hanoak Telore"
                      cardSubname="Tax Assistant"
                      cardCity="Pune"
                      description="Impeccable surgery physiotherapy home service provided by PhysioRelief. The convenience of post-surgery care in my own space was a relief. The expert therapist ensured a smooth recovery with personalized exercises and a compassionate approach. Punctual, professional, and highly skilled, this service played a crucial role in my rehabilitation. I highly recommend PhysioRelief for anyone in need of specialized post-surgery physiotherapy."
                  />
              </div>
              <div className='Owl__Card'>
                <Card
                      cardName="Sarthak Butala"
                      cardSubname="IT Engineer"
                      cardCity="Hydrebad"
                      description="Exceptional physiotherapy services at. From start to finish, the care I received was exemplary. The team's expertise, compassion, and dedication made every session productive and rewarding. With their guidance, I've made remarkable progress in my recovery. The clinic's modern facilities provided a comfortable environment for rehabilitation. I am grateful for the personalized attention and support, and I highly recommend to anyone in need of physiotherapy."
                  />
              </div>
              <div className='Owl__Card'>
                <Card
                      cardName="Pravin Sonawane"
                      cardSubname="Athelete"
                      cardCity="Nashik"
                      description="My experience with physiotherapy services exceeded all expectations. The team's professionalism and personalized approach made me feel valued and supported throughout my journey to recovery. Their expertise and encouragement motivated me to push through challenges and achieve significant progress. The clinic's modern facilities enhanced the overall experience, ensuring comfort and convenience during each session. I am immensely grateful for the care I received and would confidently recommend to anyone seeking top-notch physiotherapy services.."
                  />
              </div>

            {/* Add more CardTest components as needed */}
          </OwlCarousel>
        </div>
      </div>
    );
  };
  
  export default Owldemo1;
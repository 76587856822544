import React from 'react'
import "../Careerpages/Careerpages.css"; 
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default function Careerhradmin() {
  return (
    <>
        <div className="container-fluid Career_pages_outer">
            <div className="container Career_pages_inner">
            <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Career_pages_heading'>HR / Admin</h1>
                        <div className="mx-auto border-3 mb-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Career"><div className='career-back-button'><FontAwesomeIcon icon={faArrowLeft} 
                         className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 Career_pagesDATA g-5">
                        <div className="Career-pages_outer_data">
                            <div className="Career-pages_inner_data">
                                <div className="Career-pages_data">
                                   <p className="lead"><b>HR AND ADMIN JOB DESCRIPTION</b><br/><br/><b>Assisting with the recruitment process, including posting job ads and screening resumes.</b><ul><li>Maintaining employee records and updating HR databases.</li><li>Assisting with employee onboarding and orientation.</li><li>Administering employee benefits and handling employee inquiries.</li><li>Assisting with the performance appraisal process.</li><li>Preparing and updating HR reports.</li><li>Answering and directing phone calls and emails.</li><li>Scheduling appointments and managing calendars.</li><li> Maintaining office supplies and ordering supplies as needed.</li><li>Coordinating and scheduling meetings and events.</li><li>Performing basic bookkeeping tasks, such as invoicing and data entry.</li><li>Assisting with general office tasks, such as filing and photocopying.</li></ul><b>Candidate Profile</b><ul><li>BBA, BCA, BSc, B.Com, BBM, M.Com, MBA or PGDBM</li><li>0 to 3 Years Relevant Experience</li><li>Freshers can Apply</li><li>Verbal & Written Communication Skills</li><li>Hardworking & Presentable</li><li>Candidates who can manage Hectic Schedule</li></ul><b>Job Location & Job Type</b><br/><ul><li>Location : Pune</li><li>Full-time Job, Regular / Permanent Job</li><li>Work from Office, No WFH</li></ul><br/><b>Benefits</b><br/><ul><li>Fixed Salary</li><li>Weekly Off, Festival Leaves, Paid Leaves</li><li>Leave encashment</li><li>Health & PA Insurance Benefits</li></ul><br/><b>How to Apply?</b><br/><ul><li>Mobile Number :- <Link to="tel:+919421619929"  target="blank"><span>+91 9421619929</span></Link></li><li>Gmail :- <Link to="mailto:hrphysiorelief@gmail.com"  target="blank"><span>hrphysiorelief@gmail.com</span></Link></li><li>Or, Apply through our Portal.</li></ul></p>
                                </div>
                                <div className="row pt-3">
                                <div className="col-12">
                                    <div className="Careercard__texts">
                                            <div className='Careercard__btn__outer'>
                                                <div className="Careercard__btn__inner">
                                                    <Link to="mailto:hrphysiorelief@gmail.com"  target="blank" className='Careercard__btn__links'><span className='Careercard__btn__text'>Apply Now </span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React, { useState } from 'react';
import "../Form/Form.css";

export default function Form() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    service: '',
    appoinment_date: '',
    appoinment_time: '',
    address: '',
    description: '',
    email_id: ''
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop the form submission if there are errors
    }

    try {
      await fetch('https://script.google.com/macros/s/AKfycbx-p6RJRkI2KW52YTrXaYlrQWwo-Mhh_502ry8boEM5lUVOtenA4It9gQjX3DZvSZoW/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      showAlert('Thank you! Your appointment has been booked successfully!', 'success');

      // Reset form data
      setFormData({
        name: '',
        mobile: '',
        service: '',
        appoinment_date: '',
        appoinment_time: '',
        address: '',
        description: '',
        email_id: ''
      });

      setErrors({}); // Reset errors after submission
    } catch (error) {
      console.error('Error:', error);
      showAlert('Failed to book appointment. Please try again.', 'error');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showAlert = (message, type) => {
    const alertPopup = document.createElement('div');
    alertPopup.textContent = message;
    alertPopup.classList.add(type === 'success' ? 'custom-alert' : 'custom-alert-error');
    document.body.appendChild(alertPopup);
    setTimeout(() => alertPopup.remove(), 1000);
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if name is entered
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    // Check if email is valid
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email_id || !emailPattern.test(formData.email_id)) {
      newErrors.email_id = 'Valid email is required';
    }

    // Check if mobile is 10 digits
    if (!formData.mobile || formData.mobile.length !== 10) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    // Check if service is selected
    if (!formData.service) {
      newErrors.service = 'Please select a service';
    }

    // Check if appointment date and time are entered
    if (!formData.appoinment_date) {
      newErrors.appoinment_date = 'Appointment date is required';
    }
    if (!formData.appoinment_time) {
      newErrors.appoinment_time = 'Appointment time is required';
    }

    // Check if address is entered
    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }

    return newErrors;
  };

  return (
    <div className="Appoinment_From">
      <div className="container">
        <div className="row">
          <div className="col-12 g-4 bookAppoinment">
            <div className="text-white">
              <h3 className='bookAppoinment_heading'>APPOINTMENT FORM</h3>
              <form className="row g-3 text-center" onSubmit={handleSubmit}>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleChange}
                    required
                  />
                  {errors.email_id && <span className="error">{errors.email_id}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number"
                    maxLength="10"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                  {errors.mobile && <span className="error">{errors.mobile}</span>}
                </div>
                <div className="col-12">
                  <select
                    className="form-select"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>Select Your Service</option>
                    <option value="Physiotherapy">Physiotherapy</option>
                    <option value="Neurotherapy">Neurotherapy</option>
                    <option value="Massage Therapy">Massage Therapy</option>
                    <option value="Sport Injuries">Sport Injuries</option>
                  </select>
                  {errors.service && <span className="error">{errors.service}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="date"
                    className="form-control"
                    name="appoinment_date"
                    value={formData.appoinment_date}
                    onChange={handleChange}
                    required
                  />
                  {errors.appoinment_date && <span className="error">{errors.appoinment_date}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="time"
                    className="form-control"
                    name="appoinment_time"
                    value={formData.appoinment_time}
                    onChange={handleChange}
                    required
                  />
                  {errors.appoinment_time && <span className="error">{errors.appoinment_time}</span>}
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                  {errors.address && <span className="error">{errors.address}</span>}
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control"
                    placeholder="Issue"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn-physio">
                    <h4>Book Now</h4>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

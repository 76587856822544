import React from 'react'
import "../Bestservices/Bestservices.css"; 
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
export default function Massage_Therapy() {
  return (
    <>
        <div className="container-fluid Bestservices_outer">
            <div className="container Bestservices_inner">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Bestservices_heading'>Massage Therapy</h1>
                        <div className="mx-auto border-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Services"><div className='services-back-button'><FontAwesomeIcon icon={faArrowLeft} className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 bestservicesIMG g-5">
                        <div className="best_services_outer">
                            <div className="best_services_inner">
                                <div className="best_services_img">
                                    <img src="assets/massage.jpeg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 bestservicesDATA g-5">
                        <div className="best_services_outer_data">
                            <div className="best_services_inner_data">
                                <div className="best_services_data">
                                <p className="lead">Massage therapy services involve the manipulation of soft tissues in the body to promote health and well-being. These services are typically offered by trained massage therapists who use various techniques such as kneading, rubbing, and applying pressure to muscles, tendons, and ligaments. Massage therapy can help alleviate muscle tension, reduce stress, improve circulation, and promote relaxation. It is often used as a complementary therapy to support overall health and wellness.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React from 'react'
import "../Bestservices/Bestservices.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons' 
export default function Geriatric() {
  return (
    <>
        <div className="container-fluid Bestservices_outer">
            <div className="container Bestservices_inner">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Bestservices_heading'>Geriatric</h1>
                        <div className="mx-auto border-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Services"><div className='services-back-button'><FontAwesomeIcon icon={faArrowLeft} className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 bestservicesIMG g-5">
                        <div className="best_services_outer">
                            <div className="best_services_inner">
                                <div className="best_services_img">
                                <img src="assets\Geriatric.jpg" alt="Img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 bestservicesDATA g-5">
                        <div className="best_services_outer_data">
                            <div className="best_services_inner_data">
                                <div className="best_services_data">
                                <p className="lead">Geriatric physiotherapy, also known as geriatric physical therapy, is a specialized branch of physiotherapy that focuses on the assessment, treatment, and rehabilitation of older adults. As people age, they may experience a variety of physical changes and health conditions that can affect their mobility, balance, strength, and overall function. Geriatric physiotherapists are trained to address these specific needs and help older adults maintain or improve their quality of life.</p><p className="lead"><h3>The goals of geriatric physiotherapy may include:</h3><ol><li>Improving mobility and balance to reduce the risk of falls and maintain independence.</li><li>Managing chronic conditions such as arthritis, osteoporosis, and Parkinson's disease to minimize pain and maximize function.</li><li>Rehabilitating after surgery or illness, such as joint replacements or strokes, to regain strength and function.</li><li>Enhancing cardiovascular fitness through appropriate exercise programs tailored to the individual's abilities.</li><li>Addressing issues related to posture, gait, and movement patterns to improve overall mobility and prevent further decline.</li><li>Providing education and support to help older adults and their caregivers manage their conditions and maintain optimal health.</li></ol></p><p className="lead"><h3>Geriatric physiotherapy treatments may include a combination of:
                                </h3><ol><li>Exercise therapy to improve strength, flexibility, endurance, and balance.</li><li>Manual therapy techniques such as joint mobilization and soft tissue massage to reduce pain and improve mobility.</li><li>Balance and fall prevention exercises to reduce the risk of falls and associated injuries.</li><li>Assistive devices and adaptive equipment to help older adults maintain independence with daily activities.</li><li>Home exercise programs to reinforce treatment goals and promote ongoing progress.</li><li>Education on safe movement strategies, proper body mechanics, and fall prevention strategies.</li></ol></p><p className="lead">Geriatric physiotherapists take a holistic approach to care, considering the unique needs and circumstances of each individual. They may collaborate with other healthcare professionals, such as physicians, occupational therapists, and social workers, to provide comprehensive care and support for older adults. The ultimate goal of geriatric physiotherapy is to optimize the physical function, independence, and quality of life of older adults, allowing them to age with dignity and vitality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

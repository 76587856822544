import React from 'react'
import "../Heroheading/Heroheading.css";
import Form1 from '../Form/Form1.js';
import { Link } from "react-router-dom";

export default function Heroheading() {
  return (
    <>
        <div className="container-fluid">
            <div className="container">
                <div className="row heading-flex">
                    <div className="col-12 col-md-8">
                        <div className="row">
                        <div className="col-12">
                            <div className="heading">
                                <div className="caption">
                                    <p>WELCOME TO PhysioRelief</p>
                                </div>
                                <div className="main_heading">
                                    <p className='tag_line'>Unlock your potential to Get The Relief You’ve Been Waiting For.</p>
                                    <p>Discover our Expert & Professional Physiotherapy Doctor At Your Home.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-5 mb-2">
                            <div className="hero_buttons">
                                <div className="hero_button1">
                                    <Link to="/services">Services</Link>
                                </div>
                                <div className="hero_button1">
                                    <Link to="/about">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 hero-form">
                        <Form1/>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React from 'react'
import "../Footer/Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
        <>
            <div className="footer__outer text-white text-center">
                <div className="container-fuild">
                    <div className="container pt-5 pb-5">
                        <div className="row" id="footer">
                            <div className="col-12">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-4 footer__logo__Outer">
                                            <div className="footer__logo">
                                                <img src="assets/Physio_Relief_Logo.png" alt="Img" />
                                            </div>
                                            <h2>Physio <span className='green'>Relief</span> </h2>
                                            <p className="lead footer-caption-text py-4"><small>Unlock your potential for pain free life at your home.</small></p>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                 <h2 className="text-white border-3 title-border-bottom pb-2">Department Service</h2>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <ul className="list-unstyled">
                                                            <li><Link to="/Pune" className="text-decoration-none footer-caption-text">Pune</Link></li>
                                                            <li><Link to="/Pimpri_Chinchwad" className="text-decoration-none footer-caption-text">Pimpri - Chinchwad</Link></li>
                                                            <li><Link to="/Mumbai" className="text-decoration-none footer-caption-text">Mumbai</Link></li>
                                                            <li><Link to="/Bangalore" className="text-decoration-none footer-caption-text">Bangalore</Link></li>
                                                            <li><Link to="/Nagpur" className="text-decoration-none footer-caption-text">Nagpur</Link></li>
                                                            <li><Link to="/Nanded" className="text-decoration-none footer-caption-text">Nanded</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <h2 className="text-white border-3 title-border-bottom pb-2">HEAD OFFICE</h2>
                                                    <div className="col-12">
                                                            <ul className="list-unstyled">
                                                                <li><small className="footer-caption-text">27 Meridian, Ravet, Pune 412101</small></li>
                                                                <li><small className="footer-caption-text">Phone:&nbsp;&nbsp;<Link to="tel:+919022972131" target="blank">+91 9022972131</Link></small></li>
                                                                <li><small className="footer-caption-text">Email:&nbsp;&nbsp;<Link to="mailto:contactphysiorelief@gmail.com" target="blank">contactphysiorelief@gmail.com</Link></small></li>
                                                                <li><small className="footer-caption-text">Office Time:&nbsp;&nbsp;08:00 AM - 08:00 PM</small></li>
                                                            </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                  <h2 className='text-white'>Connect With Us</h2>
                                                  <div className="mx-auto border-3 title-border-bottom w-50"></div>
                                                  <div className="col-12 pt-3">
                                                    <div className="social-buttons "> 
                                                    <Link to="https://wa.me/+919022972131" className="social-margin text-decoration-none"target='_blank'> 
                                                            <div className="social-icon WhatsApp_icon_footer">
                                                            <img src="assets/WhatsApp-footer.png" alt=""/>
                                                            </div>
                                                        </Link>
                                                        <Link to="tel:+919022972131" className="social-margin text-decoration-none" target="blank">
                                                            <div className="social-icon Calling_icon_footer">
                                                            <img src="assets/Calling_footer.png" alt=""/>
                                                            </div> 
                                                        </Link>
                                                        <Link to="https://www.instagram.com/physio_relief_24?igsh=YzljYTk1ODg3Zg==" className="social-margin text-decoration-none" target="blank">
                                                            <div className="social-icon Instagram_icon_footer">
                                                            <img src="assets/Instagram_footer.png" alt=""/>
                                                            </div> 
                                                        </Link>
                                                        <Link to="https://www.facebook.com/profile.php?id=61566325861618&mibextid=ZbWKwL" className="social-margin text-decoration-none" target="blank">
                                                            <div className="social-icon Instagram_icon_footer">
                                                            <img src="assets/Facebook_footer.png" alt=""/>
                                                            </div> 
                                                        </Link>
                                                        <Link to="mailto:enquiry@thephysiorelief.com" className="social-margin text-decoration-none" target="blank">
                                                            <div className="social-icon Instagram_icon_footer">
                                                            <img src="assets/Mail_footer.png" alt=""/>
                                                            </div> 
                                                        </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="opacity-100"/>                  
                                </div>
                            </div>
                        </div>
                        <div className="row text-center py-1">
                            <p className='text-white'>Copyright &copy; 2023-2024 PhysioRelief Pvt.Ltd</p>
                        </div>
                        <div className="row text-center">
                            <div className="col-12 col-md-4"><Link to="assets/Privacy_Policy.pdf" target='_blank'>Privacy Policy</Link></div>
                            <div className="col-12 col-md-4"><Link to="assets/Terms_and_Conditions.pdf" target='_blank'>Terms & Condition</Link></div>
                            <div className="col-12 col-md-4"><Link to="assets/Refund_Policy.pdf" target='_blank'>Refund Policy</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </div>
  )
}

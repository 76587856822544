import React from 'react'
import "./Homepage.css";
import Heroheading from '../Heroheading/Heroheading.js';
import About from '../About/About.js';
import Services from '../Services/Services.js';
import Testimonials from '../Testimonials/Testimonials.js';
// import Doctors from '../Doctors/Doctors.js';
export default function Homepage() {
  return (
    <>
      <div className='container-fluid p-0'>
        <div className="Homepage">
          <div className="hero-img">
            <Heroheading />
          </div>
        </div>
       <div id='about'>
       <About />
       </div>
       
        <div  id='services'>
        <Services />
        </div>
        {/* <Doctors /> */}
        <Testimonials />
      </div>
    </>
  )
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginForm.css";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email_id: '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:1421/physio-relief/user-login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        navigate('/sessions-audit');
      }
      if (!response.ok) {
        throw new Error('Failed to login');
      }

      // Customizing the alert popup with CSS
      const alertPopup = document.createElement('div');
      alertPopup.textContent = response.message;
      alertPopup.classList.add('custom-alert'); // Add your CSS class here

      document.body.appendChild(alertPopup);

      // Reset form fields if needed
      setFormData({
        email_id: '',
        password: ''
      });

      // Automatically remove the popup after 5 seconds
      setTimeout(() => {
        alertPopup.remove();
      }, 5000);

    } catch (error) {
      console.error('Error:', error);

      // Customizing the error alert popup with CSS
      const alertPopup = document.createElement('div');
      alertPopup.textContent = error.message;
      alertPopup.classList.add('custom-alert-error'); // Add your CSS class here

      document.body.appendChild(alertPopup);

      // Automatically remove the popup after 5 seconds
      setTimeout(() => {
        alertPopup.remove();
      }, 5000);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/Audit");
  };

  return (
    <>
    <div className="">
        <div className="container">
          <div className="login-form-new">
              <div className="row">
                <div className="title col-12 g-5">
                  <b className="welcome">Welcome!</b>
                  <p style={{ fontSize: '16px' }}>Enter Email ID and Password to login</p>
                </div>
                <div className="form col-12 pb-5">
                  <div className="row text-center g-3">
                    <div className="col-12 mx-auto">
                      <input
                        type="text"
                        name="email_id"
                        placeholder="Email ID"
                        value={formData.email_id}
                        onChange={handleChange}
                        label="Email ID"
                        className="form-control Login__Form"
                        fullWidth
                      />
                    </div>
                    <div className="col-12 mx-auto">
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Password"
                        label="password"
                        className="form-control Login__Form"
                        fullWidth
                      />
                    </div>
                    <div className="col-10 mx-auto btn-physio">
                      <div
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Login
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    </>
  );
};

export default LoginForm;

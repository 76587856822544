import React from 'react'
import "../Careerpages/Careerpages.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default function Careercaretaker() {
  return (
    <>
        <div className="container-fluid Career_pages_outer">
            <div className="container Career_pages_inner">
            <div className="row">
                    <div className="col-12 text-center">
                        <h1 className='Career_pages_heading'>Physiotherapist</h1>
                        <div className="mx-auto border-3 mb-3 title-border-bottom w-25 py-2"></div>
                        <Link to="/Career"><div className='career-back-button'><FontAwesomeIcon icon={faArrowLeft} 
                         className='faArrowLeft'/>Previous</div></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 Career_pagesDATA g-5">
                        <div className="Career-pages_outer_data">
                            <div className="Career-pages_inner_data">
                                <div className="Career-pages_data">
                                   <p className="lead"><b>Roles and Responsibilities:</b><br/><br/>Work with patients who have a range of conditions, including neurological, neuromusculoskeletal, cardiovascular, respiratory etc;<ul><li>Diagnose, assess and treat their physical problem/condition;</li><li>Develop and review treatment programs that encourage exercise and movement by the use of a range of techniques</li><li>You will be needed to visit patients in their own home.</li><li>You will have to travel between appointments for the same.</li></ul><b>Requirement</b>Bachelor/Master of Physiotherapy<ul><li>BPTh / MPTh</li><li>0 to 3 Years Relevant Experience</li><li>Freshers can Apply</li><li>Hardworking & Presentable</li><li>Candidates who can manage Hectic Schedule</li><li>Must have 2-Wheeler Driving License</li></ul><b>Job Location & Job Type</b><br/>Location : Pune, Pimpri-Chinchwad, Nanded, Nagpur, Mumbai, Bangalore.<ul><li>Full-time Job, Regular / Permanent Job</li><li>Only for Home Visit</li></ul><b>Benefits</b><ul><li>Fixed Salary</li><li>Incentives For Extra Productivity</li><li>Weekly Off, Festival Leaves, Paid Leaves</li><li>Leave encashment</li><li>Fuel Allowance Extra</li><li>Health & PA Insurance Benefits</li></ul><br/><b>How to Apply ?</b><ul><li>Mobile Number :- <Link to="tel:+919421619929"  target="blank"><span>+91 9421619929</span></Link></li><li>Gmail :- <Link to="mailto:hrphysiorelief@gmail.com"  target="blank"><span>hrphysiorelief@gmail.com</span></Link></li><li>Or, Apply through our Portal.</li></ul></p>
                                </div>
                                <div className="row pt-3">
                                <div className="col-12">
                                    <div className="Careercard__texts">
                                            <div className='Careercard__btn__outer'>
                                                <div className="Careercard__btn__inner">
                                                    <Link to="mailto:hrphysiorelief@gmail.com"  target="blank" className='Careercard__btn__links'><span className='Careercard__btn__text'>Apply Now </span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React from 'react'
import "../About/About.css";
import Form from '../Form/Form.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserDoctor, faMapLocationDot, faTags, faPiggyBank } from '@fortawesome/free-solid-svg-icons'

export default function About() {
  return (
    <>
        <div className="About_outer">
            <div className="container-fuild">
                <div className="container pb-5">
                    <div className="row pb-5 justify-content-center">
                            <div className="row About_title text-center">
                                    <div className="col-12">
                                        <h1>About <span className='green'>US</span></h1>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <p>Welcome to PhysioRelief! We are dedicated to enhancing your well-being through personalized and professional physiotherapy services. Our experienced team is committed to providing expert care to help you recover from injuries, manage pain, and optimize your physical health. Discover a tailored approach to rehabilitation and wellness at your home.</p>
                                    </div>
                                </div>
                        <div className="col-12 col-md-7">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <h2>Welcome to Physio<span className='green'>Relief</span></h2>
                                        <p className='lead'>Your health is our priority</p>
                                    </div>
                                </div>
                            <div className="row cards_about_outer">
                                <div className="col-12 col-md-6 g-4">
                                    <div className="cards_about About-card1 text-center align-items-center">
                                    <div className="About-icon_div">
                                       <FontAwesomeIcon icon={faUserDoctor} className='About_Icon'/>
                                    </div>
                                        <div className="card-body">
                                            <h3 className="card_about_titles green">Qulified Doctor</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 g-4">
                                    <div className="cards_about About-card1 text-center align-items-center">
                                        <div className="About-icon_div">
                                          <FontAwesomeIcon icon={faMapLocationDot} className="About_Icon"/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className="card_about_titles green">At- Home Service</h3>
                                        </div>
                                    </div>
                                </div>
                                                        
                            </div>
                            <div className="row cards_about_outer">
                                <div className="col-12 col-md-6 g-4">
                                        <div className="cards_about About-card1 text-center align-items-center">
                                        <div className="About-icon_div">
                                          <FontAwesomeIcon icon={faTags} className="About_Icon"/>
                                        </div>                                             
                                        <div className="card-body">
                                                <h3 className="card_about_titles green">Discounted Packages</h3>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-12 col-md-6 g-4">
                                    <div className="cards_about About-card1 text-center align-items-center">
                                        <div className="About-icon_div">
                                          <FontAwesomeIcon icon={faPiggyBank} className="About_Icon"/>
                                        </div>                                        
                                        <div className="card-body">
                                            <h3 className="card_about_titles green">Free Online Cousultation</h3>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <Form/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

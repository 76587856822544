import React from 'react'
import "../Services/Services.css";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <>
        <div className="Services_outer">
            <div className="container-fuild">
                <div className="container pb-5">
                    <div className="row Services_title text-center">
                            <div className="col-12 Services_title_inner">
                                <h1>Best <span className='green'>Services</span></h1>
                            </div>
                            <div className="col-12 mt-3 Services_caption">
                                <p><b>Explore our range of specialized physiotherapy services.</b></p>
                            </div>
                    </div>
                    <div className="row Services_inner">
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Neurological.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Neurological</h3>
                                    <p className='know_more lead'> <Link to="/Neurological">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Orthopedic.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Orthopedic</h3>
                                    <p className='know_more lead'> <Link to="/Orthopedic">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Geriatric.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Geriatric</h3>
                                    <p className='know_more lead'> <Link to="/Geriatric">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Pediatric.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Pediatric</h3>
                                    <p className='know_more lead'> <Link to="/Pediatric">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Gynecological.webp" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Gynecological</h3>
                                    <p className='know_more lead'> <Link to="/Gynecological">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Post_Surgery.jpeg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Post Surgery</h3>
                                    <p className='know_more lead'> <Link to="/Post_Surgery">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\post-fracture.jpeg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Post Fracture</h3>
                                    <p className='know_more lead'> <Link to="/Post_Fracture">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Sport_Injury.jpeg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Sport Injury</h3>
                                    <p className='know_more lead'> <Link to="/Sport_Injury">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Cardic_Rehab.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Cardiac Rehab</h3>
                                    <p className='know_more lead'> <Link to="/Cardiac_Rehab">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Physiotherapy.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Physiotherapy</h3>
                                    <p className='know_more lead'> <Link to="/Physiotherapy">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-3">
                            <div className="service_card_outer">
                            <div className="cards_services1 g-2">
                                <div className="img_services1">
                                    <img src="assets\Respiratory_Physiotherapy.jpg" alt="Img" />
                                </div>
                                <div className="title_cards_services1">
                                    <h3>Respiratory Physiotherapy</h3>
                                    <p className='know_more lead'> <Link to="/Respiratory_Physiotherapy">Know More</Link></p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="row cards_services2_outer">
                        <h2 className='text-center py-4 fs-1'>We provide below services at your home :-</h2>
                        <div className="col col-md-4 col-lg-3 g-2">
                            <Link to="/Massage_Therapy">
                            <div className="cards_services2 text-center align-items-center Massage_Therapy">
                                <div className="cards_services2_body">
                                  <h2><Link to='/Massage_Therapy' className="card-services2-titles">Massage Therapy</Link></h2>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col col-md-4 col-lg-3 g-2">
                            <Link to="/Care_Taker">
                                <div className="cards_services2 text-center align-items-center Care_Taker">
                                    <div className="cards_services2_body">
                                 <h2><Link to='/Care_Taker' className="card-services2-titles">Care Taker</Link></h2>

                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 g-2">
                            <Link to="/Blood_Test">
                            <div className="cards_services2 text-center align-items-center Blood_Test">
                                <div className="cards_services2_body">
                                  <h2><Link to='/Blood_Test' className="card-services2-titles">Blood Test</Link></h2>
                                </div>
                            </div>
                            </Link>
                        </div>                       
                        <div className="col-12 col-md-12 col-lg-3 g-2">
                            <Link to="/Physiotherapy">
                            <div className="cards_services2 text-center align-items-center Physiotherapy">
                                <div className="cards_services2_body">
                                 <h2><Link to='/Physiotherapy' className="card-services2-titles">Physiotherapy</Link></h2>
                                </div>
                            </div>
                            </Link>
                        </div>                       
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

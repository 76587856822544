// LoginModule.js
import React from "react";
import "./LoginForm.css"; // Import the CSS file
import { Routes, Route } from "react-router-dom";
import logo from "./Physio_Nav.png";
import LoginForm from "./LoginForm";
import Audit from "./Audit";

const LoginModule = () => {
  return (
    <div className="container-fluid mainDiv py-5">
      <div className="row d-flex align-items-center">
        <div className="col-12 col-md-10 col-lg-6 mx-auto">
          <div className="loginBox ">
            <div className="row text-center p-3">
              <div className="col-12 col-md-6 col-lg-5 artheLeapLogo mt-5 mt-md-0 p-0">
                <div className="logo-tagline mt-4">
                  <img className="mainLogo" alt="" src={logo}  style={{width:'50%'}}/>
                  <h2>Physio <span className='green'>Relief</span> </h2> 
                  <p className="artha-tagline">
                    <span className="slogen">
                    Your health is our priority</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-7 arthleapComponent">
                <Routes>
                  <Route path="/" element={<LoginForm />} />
                  <Route path="/Audit" element={<Audit />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModule;

import React from 'react'
import "../Pricecard/Pricecard.css";
import { Link } from "react-router-dom";

export const Pricecard =({
    packagesName,
    packagePrice,
    pkalistpoint1,
    pkalistpoint2,
    pkalistpoint3,
    pkalistpoint4,
    pkabuttonText,
    packagesLink
})=>{
    return(
        <>
        <div className="pka_Outer__Pricecard">
            <div className="pka_container">
                 <div className="pka_inner__Headings">
                     <h4 className="pka_title__Pricecard text-center p-0">{packagesName}</h4>
                </div>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-12 pt-5">
                        <h2 className='package_price text-center'>
                            {packagePrice}
                        </h2>
                            <div className="package_content">
                                <ul className='package_content_list'>
                                    <li>{pkalistpoint1}</li>
                                    <li>{pkalistpoint2}</li>
                                    <li>{pkalistpoint3}</li>
                                    <li>{pkalistpoint4}</li>
                                </ul>
                            </div>
                    </div>
                </div>
                <div className="row">
                   <div className="col-12 pb-4">
                       <div className="pka__texts">
                            <div className='pka__btn__outer'>
                                <div className="pka__btn__inner">
                                <Link to={packagesLink} className='pka__btn__links'><span className='pka__btn__text'>{pkabuttonText} </span></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

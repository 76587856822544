import React from 'react'
import Owldemo1 from '../Owlcarousel/Owl.js'
import '../Testimonials/Testimonials.css'; 

export default function Testimonials() {
  return (
    <>
      <div className="container-fluid Testimonials">
        <div className="container py-1">
                <div className="row">
                    <div className="col-12 testimonials_inner text-center">
                      <h1 className='testimonials_title'>Patient <span className="green">Review</span></h1>
                      <p className='lead mt-5 testimonials_title'>"Exceptional care, compassionate service: a patient's journey redefined."</p>
                  </div>
              </div>
            <div className="row text-center">
                <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <Owldemo1/>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>   
    </>
  )
}

import React from 'react'
import { Careercard } from '../Careercard/Careercard.jsx';
import "../Career/Career.css"; 
export default function Career() {
  return (
    <>
        <div className="Careers">
            <div className="container-fluid">
                <div className="container">
                    <div className="row Careers_Outer">
                        <div className="row">
                            <div className="col-12">
                              <h1 className='text-center Puneprice_title'><span className="green">Careers</span></h1>
                             <div className="mx-auto border-3 title-border-bottom w-25"></div>
                            </div>
                        </div>
                        <div className="row pt-5 Careers_inner">
                            <div className="col-12 col-md-6 g-5 Careers_card">
                                <Careercard
                                    CareersName="Part Time / Full Time"
                                    Careerslist1="Physiotherapist"
                                    Careerslist1link="/Careercaretaker"
                                    Careerslist2="Care Taker"
                                    Careerslist2link="/Careermassager"
                                    Careerslist3="Massage Therapist"
                                    Careerslist3link="/Careerbloodtester"
                                    
                                />
                            </div>
                            <div className="col-12 col-md-6 Careers_card g-5">
                                <Careercard
                                    CareersName="Office Staff"
                                    Careerslist1="HR / Admin"
                                    Careerslist1link="/Careerhradmin"
                                    Careerslist2="Account
                                    Operation Executive"
                                    Careerslist2link="/Careeraccountexecutive"
                                    Careerslist3="Sales & Customer Support Executive"
                                    Careerslist3link="/Careercustomersupport"
                                    
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
